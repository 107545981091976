@use 'sass:map';
@use '../core/theming/theming';
@use '../core/typography/typography';
@use '../core/typography/typography-utils';
@use '../core/style/private';

$target-height: 22px;
$font-size: 10px;
$vertical-padding: private.private-div($target-height - $font-size, 2);

$handset-target-height: 30px;
$handset-font-size: 14px;
$handset-vertical-padding:
    private.private-div($handset-target-height - $handset-font-size, 2);

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $background: map.get($config, background);

  .mat-tooltip {
    background: theming.get-color-from-palette($background, tooltip, 0.9);
  }
}

@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2014-config(
      theming.get-typography-config($config-or-theme));
  .mat-tooltip {
    font-family: typography-utils.font-family($config);
    font-size: $font-size;
    padding-top: $vertical-padding;
    padding-bottom: $vertical-padding;
  }

  .mat-tooltip-handset {
    font-size: $handset-font-size;
    padding-top: $handset-vertical-padding;
    padding-bottom: $handset-vertical-padding;
  }
}

@mixin _density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-tooltip') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include _density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}

