
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
@import '~@angular/material/theming';
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
// Plus imports for other components in your app.

$livingprint-typography: mat.define-typography-config(
  $font-family: Rubik,
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($livingprint-typography);

$livingprint-print-provider-onboarding-website-primary: (
  50: #e1f4fc,
  100: #b2e3f7,
  200: #80d0f1,
  300: #51beeb,
  400: #2fb0e8,
  500: #11a3e5,
  600: #0c95d6,
  700: #0582c3,
  800: #0572af,
  900: #00528d, // Exact Color
  A100: #badeff,
  A200: #8dcaff,
  A400: #36a4ff,
  A700: #0c74dd,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$livingprint-print-provider-onboarding-website-accent: (
  50: #e7f5e6,
  100: #c7e5c2,
  200: #a3d59b,
  300: #7dc572, // Exact Color
  400: #60b953,
  500: #47ac35,
  600: #3d9d2d,
  700: #318b22,
  800: #257a18,
  900: #0e5c04,
  A100: #d0ffc2,
  A200: #adff98,
  A400: #4bff2f,
  A700: #00d400,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$livingprint-print-provider-onboarding-website-primary: mat-palette($livingprint-print-provider-onboarding-website-primary);
$livingprint-print-provider-onboarding-website-accent: mat-palette($livingprint-print-provider-onboarding-website-accent);

// The warn palette is optional (defaults to red).
$livingprint-print-provider-onboarding-website-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$livingprint-print-provider-onboarding-website-theme: mat-light-theme((
  color: (
    primary: $livingprint-print-provider-onboarding-website-primary,
    accent: $livingprint-print-provider-onboarding-website-accent,
    warn: $livingprint-print-provider-onboarding-website-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($livingprint-print-provider-onboarding-website-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Rubik, sans-serif; font-size: 16px; }
